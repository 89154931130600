<template>
  <div class="w-full h-full">
    <Navigation />
    <div
      class="w-full max-w-7xl mx-auto flex flex-col mt-6 gap-4"
      style="height: calc(100vh - 80px)"
    >
      <div
        class="max-w-4xl min-w-[360px] min-h-[320px] border border-gray-300 w-full rounded-md shadow-xl mx-auto flex items-center justify-center"
      >
        <!-- question -->
        <div class="w-full h-full flex items-center justify-center">
          <div v-if="tst == true">
            You are completed
            <div class="flex gap-4"></div>
          </div>
          <div v-else class="h-full w-full">
            <div
              v-if="questions.length > 0"
              class="w-full h-full flex flex-col justify-center items-center"
            >
              <div class="w-full h-full flex flex-col">
                <div
                  class="text-center py-2 bg-[#001455] text-white min-h-[80px] flex flex-col justify-between"
                >
                  <div class="h-full flex items-center justify-center text-xl">
                    Question No.{{ currentQuestion + 1 }} of
                    {{ questions.length }}
                  </div>
                  <div class="text-white">
                    <span style="font-size: 26px"
                      ><strong
                        >{{
                          Math.floor(countDown / 60)
                            .toString()
                            .padStart(2, "0")
                        }}
                        : {{ (countDown % 60).toString().padStart(2, "0") }}
                      </strong></span
                    >
                  </div>
                </div>
                <div class="h-full flex flex-col gap-2">
                  <div
                    class="p-4 h-[30%] flex flex-col justify-center min-h-[90px]"
                  >
                    <p v-html="questions[currentQuestion].question_text"></p>
                  </div>
                  <div
                    class="flex flex-col md:flex-row md:flex-wrap justify-center gap-6 w-full h-[60%] py-6"
                  >
                    <button
                      class="border rounded-md px-4 p-2 w-[90%] md:w-[43%] mx-auto relative hover:border-[#001455]"
                      :class="[
                        questions[currentQuestion].ansclk == option.id
                          ? 'bg-[#001455] text-white border-gray-400'
                          : '',
                      ]"
                      :key="index"
                      v-for="(option, index) in questions[currentQuestion]
                        .answer"
                      @click="handleAnswerClick(option.is_correct, option.id)"
                    >
                      {{ option.answer_text }}
                      <div
                        class="absolute top-[22%] -left-2 md:top-5 md:-left-5 h-6 w-6 md:h-10 md:w-10 flex items-center justify-center rounded-full font-semibold"
                        :class="[
                          questions[currentQuestion].ansclk == option.id
                            ? 'bg-white text-[#001455] border-gray-600'
                            : 'text-white bg-[#001455]',
                        ]"
                      >
                        {{
                          index == 0
                            ? "A"
                            : index == 1
                            ? "B"
                            : index == 2
                            ? "C"
                            : "D"
                        }}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="flex flex-col justify-center w-full h-full">
              <div
                v-html="this.message"
                class="w-full h-32 flex flex-col justify-center items-center max-h-[358px] text-xl"
              ></div>
            </div>
            <div
              v-if="questions.length > 0"
              class="max-w-4xl min-w-[360px] bg-gray-200"
            >
              <ul
                class="flex py-2 px-6 items-center gap-4 overflow-y-auto"
                :class="
                  slice_limit > 10 ? 'justify-start' : 'md:justify-center'
                "
              >
                <div
                  v-if="this.slice_start != 0"
                  class="border px-2 bg-white rounded hover:bg-[#001455] hover:text-white text-xl w-[35px] h-[35px] leading-[40px] cursor-pointer flex items-center"
                  @click="
                    (this.slice_start = this.slice_start - slice_limit),
                      (this.slice_end = this.slice_end - slice_limit)
                  "
                >
                  <img src="../../assets/backward.png" alt="" />
                </div>
                <li
                  v-for="(i, index) in questions.slice(
                    this.slice_start,
                    this.slice_end
                  )"
                  :key="i"
                  class="border px-2 bg-white rounded hover:bg-[#001455] hover:text-white align-middle text-sm w-[35px] h-[35px] leading-[40px] cursor-pointer"
                  :class="[
                    currentQuestion == index + this.slice_start
                      ? 'border-[#001455]'
                      : '',
                  ]"
                  @click="currentQuestion = index + this.slice_start"
                >
                  {{ index + this.slice_start + 1 }}
                </li>
                <div
                  v-if="this.slice_end < questions.length"
                  class="border px-2 bg-white rounded hover:bg-[#001455] hover:text-white align-middle text-sm w-[35px] h-[35px] leading-[40px] cursor-pointer flex items-center"
                  @click="
                    (this.slice_start = this.slice_start + slice_limit),
                      (this.slice_end = this.slice_end + slice_limit)
                  "
                >
                  <img src="../../assets/fast-forward.png" alt="" />
                </div>
              </ul>
            </div>
            <div
              v-if="questions.length > 0"
              class="my-4 flex gap-4 justify-center"
            >
              <button
                v-if="currentQuestion > 0"
                class="gradient-btn"
                @click="pvsQt"
              >
                Previous
              </button>
              <button
                v-if="currentQuestion + 1 < this.questions.length"
                class="gradient-btn"
                @click="nxtQt"
              >
                Next
              </button>
              <button
                v-if="currentQuestion + 1 == this.questions.length"
                @click="ckans"
                class="gradient-btn"
              >
                Submit
              </button>
            </div>
            <div v-else class="py-4 flex items-center gap-4 justify-center">
              <router-link :to="'/'">
                <button class="gradient-btn">Home</button>
              </router-link>
              <router-link :to="{ name: 'Preview' }">
                <button class="gradient-btn">Preview</button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "McqView.vue",
  data() {
    return {
      currentQuestion: 0,
      countDown: 840,
      showScore: false,
      questions: [],
      tst: false,
      is_completed: false,
      is_submit: false,
      nextquz: 0,
      message: "",
      mcqset: "",
      paginator_limit: 6,
      slice_start: 0,
      slice_end: 0,
      slice_limit: 10,
      is_preview: false,
    };
  },
  computed: mapGetters(["userInfo"]),
  components: {
    Navigation,
  },
  async created() {
    console.log(this.userInfo.data.id);
    this.slice_end = this.slice_limit;

    this.mcqCall();
    this.tst = false;
    this.countDownTimer();
  },

  methods: {
    async mcqCall() {
      await axios
        .get(`${process.env.VUE_APP_API}/quiz-all-details/list/`)
        .then((r) => {
          if (r.data.status == 200) {
            this.mcqset = r.data.data[0];
            this.questions = r.data.data[0].question;
            this.countDown = parseInt(this.mcqset.duration) * 60;
          } else {
            this.questions = [];
            this.message = r.data.message;
          }
          if (this.questions) {
            this.questions.forEach((el) => {
              el.ansclk = "";
              el.anr = false;
            });
          }
        });
    },

    handleAnswerClick(isCorrect, id) {
      this.questions[this.currentQuestion].ansclk = id;
      this.questions[this.currentQuestion].anr = isCorrect;
      let nextQuestion = this.currentQuestion + 1;
      if (
        nextQuestion + 1 > this.slice_end &&
        this.slice_end < this.questions.length
      ) {
        this.slice_start = this.slice_start + this.slice_limit;
        this.slice_end = this.slice_end + this.slice_limit;
      }

      if (nextQuestion < this.questions.length) {
        this.currentQuestion = nextQuestion;
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        this.timer = setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    submitMcq() {
      this.showScore = true;
      this.tst = true;
    },
    nxtQt() {
      let nextQuestion = this.currentQuestion + 1;
      if (nextQuestion < this.questions.length) {
        this.currentQuestion = nextQuestion;
      }
    },
    pvsQt() {
      if (this.currentQuestion > 0) {
        let nextQuestion = this.currentQuestion - 1;
        if (nextQuestion >= 0) {
          this.currentQuestion = nextQuestion;
        }
      }
    },
    async ckans() {
      var ans_set = [];
      this.questions.forEach((el) => {
        let val = {
          ques: el.id,
          ans: el.ansclk,
        };
        ans_set.push(val);
      });
      var headerbody = {
        user: this.userInfo.data.id,
        quiz: this.mcqset.id,
        completed: true,
        ansVal: ans_set,
      };
      let percentage = (this.score / this.mcqset.question.length) * 100;
      console.log(percentage);

      await axios
        .post(`${process.env.VUE_APP_API}/quiz-taker-answer/post/`, headerbody)
        .then((r) => {
          console.log(r);
          if (r.data.status == 200) {
            this.questions = 0;
            this.message =
              '<span class="font-semibold text-3xl py-1"> Congratulations </span> <br> <span class="py-1"> Your test is now complete! </span> <br> <span class="py-1"> Please click on the Preview button to view your results </span>';
          } else {
            this.questions = 0;
            this.message =
              '<span class="font-semibold text-5xl py-1 text-red-400"> Sorry... </span> <br> <span class="py-1"> You already completed! </span> <br> <span class="py-1"> Please click on the home button to view your results </span>';
          }
        });
    },
  },
};
</script>

<style src="@/assets/CSS/ProgressTracking.css"></style>
