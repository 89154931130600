<template>
  <div class="min-h-screen flex flex-col">
    <section class="h-full">
      <Navigation />
      <div class="flex items-center h-full min-h-[calc(100vh-86px)]">
        <div
          class="min-h-[320px] min-w-[320px] h-full mx-auto rounded-lg flex flex-col justify-center shadow-lg p-6 border border-gray-300"
        >
          <h1 class="text-xl font-semibold p-2 text-start">
            Welcome to the Online Test
          </h1>
          <div class="text-justify p-2">
            <ul class="highlights">
              <li>
                The test has 3 sections namely logical reasoning, Quantitative
                Aptitude & English
              </li>
              <li>There all 50 multiple choice Questions (MCQs)</li>
              <li>The Duration of the Test is 1 Hrs</li>
              <li>There is no negative marking</li>
              <li>
                You can switch between pages to check and revalidate your answer
                before submitting the test paper
              </li>
              <li class="capitalize">
                <strong>
                  Once Submitted, You Will Not Be Able To Change The Questions
                  Or Change The Response
                </strong>
              </li>
              <li>
                Click on the preview to see your responses. "You cannot edit
                your answer here in preview"
              </li>
            </ul>
          </div>
          <div
            class=""
            v-if="
              new Date(quiz_details.start_date) <= to_day &&
              new Date(quiz_details.end_date) >= to_day
            "
          >
            <div v-if="this.is_quiz_done != true" class="py-6">
              <router-link :to="{ name: 'MCQ' }">
                <button class="gradient-btn">Start</button>
              </router-link>
            </div>
            <div v-else>
              <router-link :to="{ name: 'Preview' }">
                <button class="gradient-btn">Preview</button>
              </router-link>
            </div>
          </div>
          <div v-else>
            <p
              class="text-start px-2 text-xs md:text-base flex flex-col md:flex-row"
            >
              <span>
                <span class="px-2">Start at</span>
                <span class="font-semibold text-sm">
                  {{ new Date(quiz_details.start_date).toLocaleTimeString() }}
                  {{ new Date(quiz_details.start_date).toDateString() }}
                </span>
              </span>
              <span class="hidden md:flex px-2"> - </span>
              <span
                ><span class="px-2">End at</span>
                <span class="font-semibold text-sm">
                  {{ new Date(quiz_details.end_date).toLocaleTimeString() }}
                  {{ new Date(quiz_details.end_date).toDateString() }}
                </span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "HomeView",
  data() {
    return {
      is_quiz_done: false,
      to_day: new Date(),
      quiz_details: [],
    };
  },
  computed: mapGetters(["userInfo", "isAuthenticated"]),
  components: {
    Navigation,
  },
  created() {
    this.userQuizCall();
    this.QuizCall();
  },
  methods: {
    async QuizCall() {
      await axios
        .get(`${process.env.VUE_APP_API}/quiz-details/list/`)
        .then((r) => {
          console.log(r);
          if (r.data.status == 200) {
            this.quiz_details = r.data.data[0];
          }
        });
    },
    async userQuizCall() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/quiz-taker-details/list/?user__id=${this.userInfo.data.id}`
        )
        .then((r) => {
          if (r.data.status == 200) {
            this.is_quiz_done = true;
          }
        });
    },
  },
};
</script>

<style></style>
