import { createStore } from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

export default createStore({
  state: {
    userInfo: null,
    error: null,
    isAuthenticated: false,
  },
  getters: {
    userInfo: (state) => state.userInfo,
    loginError: (state) => state.error,
    isAuthenticated: (state) => state.isAuthenticated,
  },
  actions: {
    async loginUser({ commit }, credentials) {
      await axios
        .post(`${process.env.VUE_APP_API}/login/`, credentials)
        .then((response) => {
          if (response.data.status == 200) {
            commit("setUserInfo", response.data);
          } else {
            commit("errMessage", response.data.message);
            setTimeout(() => {
              commit("errMessage", "");
            }, 3000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async logOutUser({ commit }) {
      commit("logout", true);
    },
  },
  mutations: {
    setUserInfo: (state, data) => {
      state.userInfo = data;
      state.isAuthenticated = true;
      state.error = null;
    },
    errMessage: (state, data) => {
      state.error = data;
    },
    logout: (state) => {
      state.userInfo = null;
      state.userProfileInfo = null;
      state.isAuthenticated = false;
    },
  },
  modules: {},
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
