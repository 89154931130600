import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

import MCQ from "../views/AssessmentTest/McqView.vue";
import PreviewAnswer from "../views/AssessmentTest/QuizPreviewView.vue";
import Preview from "../views/AssessmentTest/QuizPreviewWAView.vue";
import Home from "../views/AssessmentTest/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import Admin from "../views/AdminLoginView.vue";
import AdminDashboard from "../views/AdminDashboardView.vue";

const routes = [
  {
    path: "/assessment",
    name: "MCQ",
    component: MCQ,
    meta: { requiresAuth: true },
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/quiz-preview-answer",
    name: "PreviewAnswer",
    component: PreviewAnswer,
    meta: { requiresAuth: true },
  },
  {
    path: "/quiz-preview",
    name: "Preview",
    component: Preview,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/admin/login",
    name: "Admin",
    component: Admin,
  },
  {
    path: "/admin/dashboard",
    name: "AdminDashboard",
    component: AdminDashboard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      if (to != "/") {
        sessionStorage.setItem("to_url", to.path);
      }
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
