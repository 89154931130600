<template>
  <div class="w-full h-full bg-gray-100">
    <Navigation />
    <div
      class="flex w-full h-full min-h-screen justify-center max-w-7xl mx-auto"
    >
      <div class="h-full w-full py-6">
        <div class="flex flex-row justify-end py-4">
          <div class="relative">
            <input
              type="text"
              class="rounded-md border py-1 pl-2 pr-8 text-sm"
              v-model="searchQuery"
              placeholder="Search by email"
            />
          </div>
        </div>
        <table class="min-w-full">
          <thead class="bg-[#001455] border-b">
            <tr class="">
              <th
                scope="col"
                class="text-base font-medium text-white px-6 py-4"
              >
                #
              </th>
              <th
                scope="col"
                class="text-base font-medium text-white px-6 py-4"
              >
                Email
              </th>
              <th
                scope="col"
                class="text-base font-medium text-white px-6 py-4"
              >
                Score
              </th>

              <th
                scope="col"
                class="text-base font-medium text-white px-6 py-4"
              >
                Date
              </th>
              <!-- <th
                scope="col"
                class="text-base font-medium text-gray-900 px-6 py-4"
              >
                Preview
              </th> -->
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(el, index) in this.filteredLeads"
              :key="el"
              class="bg-white border-b shadow-lg"
            >
              <td
                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
              >
                {{ index + 1 }}
              </td>
              <td
                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
              >
                {{ el.user.email }}
              </td>
              <td
                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
              >
                {{ el.score }}
              </td>
              <td
                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
              >
                {{
                  new Date(el.finished_at).toLocaleDateString("en-IN", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })
                }}
                -
                {{ new Date(el.finished_at).toLocaleTimeString() }}
              </td>
              <!-- <td
                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
              >
                Preview
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Navigation from "@/components/Navigation.vue";
export default {
  name: "AdminDashboardView",
  data() {
    return {
      user_test_list: [],
      searchQuery: "",
    };
  },
  computed: {
    filteredLeads() {
      const query = this.searchQuery.toLowerCase();
      if (query) {
        return this.user_test_list.filter((el) =>
          el.user.email.toLowerCase().includes(query)
        );
      } else {
        return this.user_test_list;
      }
    },
  },
  created() {
    this.getTestList();
  },

  components: {
    Navigation,
  },
  methods: {
    getTestList() {
      axios
        .get(`${process.env.VUE_APP_API}/quiz-taker-details/list/`)
        .then((response) => {
          if (response.data.status == 200) {
            this.user_test_list = response.data.data;
          } else {
            console.log("error");
          }
        });
    },
  },
};
</script>

<style></style>

