<template lang="">
  <div class="w-full shadow-lg sticky top-0 bg-white z-[100]">
    <div class="w-full h-full max-w-7xl mx-auto">
      <!-- Navigation -->
      <div class="bg-white pl-4 md:px-4 border-b-2">
        <div
          class="h-[80px] md:h-[85px] flex flex-row items-center justify-between gap-4 max-w-screen-2xl mx-auto"
        >
          <img
            src="../assets/usdc_transperent.png"
            alt=""
            class="h-[80px]"
            @click="redirectHome"
          />
          <div class="flex flex-row items-center">
            <div v-if="this.isAuthenticated != true" class="">
              <router-link :to="{ name: 'Login' }">
                <button
                  class="bg-primary px-4 py-2 rounded-lg font-semibold text-sm"
                >
                  Login
                </button>
              </router-link>
            </div>
            <div v-else>
              <button
                @click="this.logOut"
                class="bg-primary px-4 py-2 rounded-lg font-semibold text-sm"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="hidden md:hidden px-4 py-5 bg-white absolute w-full border-b border-gray-200 shadow-xl"
        id="collapseExample"
      >
        <div
          v-if="!this.isAuthenticated"
          class="flex flex-row items-center justify-center"
        >
          <ul
            v-if="this.isAuthenticated != true"
            class="block md:hidden flex flex-col gap-4 md:gap-7 text-xs md:text-sm cursor-pointer"
          >
            <li @click="scrollPosition('about-content', $event)" class="p-2">
              About us
            </li>
            <li @click="scrollPosition('faq-content', $event)" class="p-2">
              FAQs
            </li>
            <li @click="scrollPosition('enquiry-content', $event)" class="p-2">
              Enquiry
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: mapGetters(["userInfo", "isAuthenticated"]),
  created() {},
  mounted() {
    if (document.querySelector("#menuIcon")) {
      document
        .querySelector("#menuIcon")
        .addEventListener("click", function () {
          document.querySelector("#collapseExample").classList.toggle("hidden");
        });
    }
  },
  methods: {
    ...mapActions(["logOutUser"]),
    async logOut() {
      await this.logOutUser();
      if (this.isAuthenticated == false) {
        this.$router.push({ name: "Login" });
      }
    },
    redirectHome() {
      this.$router.push({ name: "Home" });
    },
    scrollPosition(Val, el) {
      const elements = document.querySelectorAll(".active-btn");
      elements.forEach((element) => {
        element.classList.remove("active-btn");
      });
      el.target.classList.add("active-btn");
      $("html, body").animate(
        { scrollTop: $(`#${Val}`).offset().top - 85 },
        1000
      );
    },
  },
};
</script>

<style scoped>
.active-btn {
  border-bottom: 3px solid #fedf23;
}
</style>
