<template lang="">
  <div class="w-full h-full bg-gray-100">
    <!-- <Navigation /> -->
    <div
      class="flex w-full h-full min-h-screen justify-center max-w-7xl mx-auto"
    >
      <div class="flex flex-col lg:flex-row min-h-screen w-full">
        <div
          class="hidden flex-col relative w-full lg:w-[50%] h-full bg-[#ffffff] justify-center items-center gap-10"
        >
          <div>
            <img src="../assets/jainonline.png" class="h-28" />
          </div>
          <img
            src="../assets/image.png"
            class="p-4 min-w-[300px] lg:max-w-[300px] 2xl:max-w-[400px]"
          />
        </div>
        <div
          class="relative w-full lg:w-full h-full bg-white md:bg-[#001455] px-3 flex flex-col justify-center items-center gap-16 text-start"
        >
          <div class="flex gap-4 items-center">
            <img
              src="../assets/jain-university-white.png"
              alt=""
              class="w-[274px]"
            />
            <img src="../assets/usdc_transperent_2.png" class="w-[274px]" />
          </div>
          <div
            class="bg-[#fff] rounded-md px-4 md:px-8 py-6 min-w-[350px] w-full max-w-[460px] shadow-lg border border-gray-200"
          >
            <form class="flex flex-col gap-4" @submit.prevent="this.submitOtp">
              <div class="flex items-center gap-2">
                <h3
                  class="blue_underline relative text-black text-base font-medium"
                >
                  Login
                </h3>
                <!-- <img src="../assets/login-icon.png" alt="" /> -->
              </div>

              <div class="">
                <form @submit.prevent="sntotp" class="flex gap-2">
                  <input
                    type="text"
                    placeholder="Enter the mail-id"
                    value=""
                    name="email"
                    id="student_email"
                    class="text-sm min-w-[290px] w-full bg-white px-0 py-1.5 border-b-2 border-gray-400 border-x-0 border-t-0 focus:border-b-2 focus:border-primary focus:ring-0 focus:outline-none"
                    required
                  />
                  <div
                    class="flex justify-between w-full min-w-[60px] min-h-[40px] h-full"
                  >
                    <button
                      v-if="this.otp_status == false"
                      class="bg-primary text-black font-medium rounded-md text-center px-5 text-xs"
                      type="submit"
                    >
                      <span v-if="!is_ld"> Get OTP </span>
                      <span v-else>Loading...</span>
                    </button>
                    <button
                      v-else
                      class="bg-primary text-black font-medium rounded-md text-center px-5 text-xs"
                      type="submit"
                    >
                      <span v-if="!is_ld"> Resend </span>
                      <span v-else>Loading...</span>
                    </button>
                  </div>
                </form>
              </div>
              <div class="flex gap-2">
                <input
                  type="text"
                  placeholder="OTP"
                  value=""
                  id="otp"
                  maxlength="6"
                  class="text-sm w-[160px] bg-white px-0 py-1.5 border-2 border-gray-400 focus:border-2 focus:border-primary focus:ring-0 focus:outline-none rounded-md text-center"
                  required
                />
                <div
                  class="flex justify-between w-full min-w-[60px] min-h-[40px] h-full"
                >
                  <button
                    v-if="this.lead_details.email != ''"
                    class="bg-primary text-black font-medium rounded-md text-center px-5 text-xs"
                    type="submit"
                  >
                    <span v-if="!ld_btn"> Submit </span>
                    <span v-else>Loading...</span>
                  </button>
                  <button
                    v-else
                    class="bg-primary text-black font-medium rounded-md text-center px-5 text-xs cursor-not-allowed"
                    type="button"
                    disabled
                  >
                    <span v-if="!ld_btn"> Submit </span>
                  </button>
                </div>
              </div>

              <div v-if="this.message != ''" class="mt-5">
                <div
                  class="py-1 px-3 bg-[#ff0000] font-light text-white text-xs text-center inline-block"
                >
                  Email not found
                </div>
                <div class="text-black text-sm font-normal mt-3">
                  Enter your registered student email
                </div>
              </div>
            </form>
          </div>
          <div class="absolute bottom-3 md:bottom-5 text-white text-xs">
            &copy; Copyright JAIN.Online. All Right Reserved.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Navigation from "../components/Navigation.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      is_ld: false,
      ld_btn: false,
      message: "",
      loginError: "",
      otp_status: false,
      lead_details: {
        name: "",
        email: "",
        otp: "",
      },
    };
  },
  components: {
    Navigation,
  },
  computed: mapGetters(["userInfo", "isAuthenticated"]),
  mounted() {},
  methods: {
    ...mapActions(["loginUser", "userDetailsGet"]),

    sntotp(el) {
      this.is_ld = true;
      this.lead_details.email = el.target.elements.student_email.value;
      let data = {
        email: this.lead_details.email,
      };
      axios
        .post(`${process.env.VUE_APP_API}/lead-otp/post/`, data)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.is_ld = false;
            this.otp_status = true;
          } else {
            this.otp_status = false;
          }
        });
    },
    async submitOtp(el) {
      this.ld_btn = true;
      this.lead_details.otp = el.target.elements.otp.value;
      let hdrVal = {
        email: this.lead_details.email,
        otp: this.lead_details.otp,
      };
      await this.loginUser(hdrVal);
      if (this.isAuthenticated == true) {
        this.$router.push({ name: "Home" });
      } else {
        this.message = "Invalid OTP";
      }
      setTimeout(() => {
        this.ld_btn = false;
        this.message = "";
      }, 3000);
    },
  },
};
</script>
<style lang=""></style>
