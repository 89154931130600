<template>
  <div class="h-full min-h-screen w-full bg-white overflow-auto z-50">
    <Navigation />
    <section
      v-if="this.quiz_details[0]"
      class="max-w-7xl w-full mx-auto h-full overflow-y-auto py-4"
    >
      <h1 class="text-center p-2 px-4 font-semibold text-xl">
        Selected Answers
      </h1>
      <div
        v-if="this.quiz_details.length > 0"
        class="flex flex-col gap-2 text-start"
      >
        <template
          v-for="(el, index) in this.quiz_details[0].useranswer"
          :key="el"
        >
          <div class="p-6 border border-gray-200 rounded-lg bg-white">
            <div class="flex items-start">
              {{ index + 1 }})
              <pre> {{ el.question.question_text }}</pre>
            </div>
            <ul class="py-2 flex flex-wrap gap-4 justify-around">
              <li
                v-for="(answer, index) in el.question.answer"
                :key="answer"
                class="w-[40%] h-full min-h-[80px] relative flex gap-2"
              >
                <div
                  v-if="el.answer"
                  class="p-2 w-full px-10 shadow-lg flex items-center"
                  :class="[
                    el.answer.id == answer.id
                      ? 'border-4 border-[#001455] text-black rounded-l-full'
                      : 'bg-white border shadow-md rounded-l-full',
                  ]"
                >
                  {{ answer.answer_text }}
                </div>
                <div
                  v-else
                  class="p-2 w-full px-10 flex items-center bg-white border shadow-md rounded-l-full"
                >
                  {{ answer.answer_text }}
                </div>

                <div
                  v-if="el.answer"
                  class="absolute top-4 -left-5 h-12 w-12 flex items-center justify-center rounded-full font-semibold"
                  :class="[
                    el.answer.id == answer.id
                      ? 'bg-[#001455] text-white'
                      : 'bg-white border shadow-md',
                  ]"
                >
                  {{
                    index == 0 ? "A" : index == 1 ? "B" : index == 2 ? "C" : "D"
                  }}
                </div>
                <div
                  v-else
                  class="absolute top-4 -left-5 h-12 w-12 flex items-center justify-center rounded-full font-semibold bg-white border shadow-md"
                >
                  {{
                    index == 0 ? "A" : index == 1 ? "B" : index == 2 ? "C" : "D"
                  }}
                </div>
              </li>
            </ul>
          </div>
        </template>
        <div class="text-center py-4">
          <router-link :to="{ name: 'Home' }">
            <button class="gradient-btn">Home</button>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import Navigation from "@/components/Navigation.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      quiz_details: [],
    };
  },
  components: {
    Navigation,
  },
  computed: mapGetters(["userInfo", "isAuthenticated"]),
  created() {
    this.userQuizCall();
  },
  methods: {
    async userQuizCall() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/quiz-taker/result-list/answer/?user__id=${this.userInfo.data.id}`
        )
        .then((r) => {
          if (r.data.status == 200) {
            this.quiz_details = r.data.data;
          }
        });
    },
  },
};
</script>

<style></style>
