<template>
  <div class="w-full h-full bg-gray-100">
    <!-- <Navigation /> -->
    <div
      class="flex flex-col items-center gap-6 w-full h-full min-h-screen justify-center max-w-7xl mx-auto bg-[#001455]"
    >
      <div class="flex gap-4 items-center">
        <img
          src="../assets/jain-university-white.png"
          alt=""
          class="w-[274px]"
        />
        <img src="../assets/usdc_transperent_2.png" class="w-[274px]" />
      </div>
      <div class="flex flex-row h-auto w-full justify-center items-center">
        <div
          id="signIn"
          class="w-[620px] h-[360px] rounded-md p-4 flex items-center justify-start bg-white"
        >
          <form @submit.prevent="handleSubmit" class="w-full">
            <h2 class="text-xl font-bold">Sign in</h2>
            <div class="mb-4 w-full text-start">
              <label
                class="leading-7 text-base text-gray-600 text-start font-semibold"
                >Email</label
              >
              <input
                ref="input"
                type="email"
                id="email"
                name="email"
                class="w-full bg-white rounded border border-gray-300 focus:primary-border focus:ring-2 focus:primary-border text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                placeholder="Email"
                required
              />
            </div>

            <div class="mb-4 text-start">
              <label class="leading-7 text-base text-gray-600 font-semibold"
                >Password</label
              >
              <div class="relative">
                <input
                  type="password"
                  id="password"
                  name="password"
                  class="w-full bg-white rounded border border-gray-300 focus:primary-border focus:ring-2 focus:primary-border text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  placeholder="Password"
                  required
                />
                <img
                  v-if="passwordType == 'password'"
                  src="../assets/eyeslash.svg"
                  @click="hideShow('password')"
                  alt=""
                  class="cursor-pointer inline-block z-50 absolute top-3 right-3"
                />
                <img
                  v-else
                  src="../assets/eye.svg"
                  @click="hideShow('password')"
                  alt=""
                  class="cursor-pointer inline-block z-50 absolute top-3 right-3"
                />
              </div>
            </div>
            <button v-if="!loading" type="submit" class="gradient-btn">
              Login
            </button>
            <button
              v-else
              type="submit"
              class="button-primary header-button w-button"
            >
              Loading...
            </button>
          </form>
          <div v-if="this.message != ''" class="mt-5">
            <div
              class="py-1 px-3 bg-[#ff0000] font-light text-white text-xs text-center inline-block"
            >
              {{ this.message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AdminLoginView",
  data() {
    return {
      loading: false,
      passwordType: "password",
      message: "",
    };
  },
  computed: mapGetters(["userInfo", "isAuthenticated"]),
  methods: {
    ...mapActions(["loginUser"]),
    hideShow(id) {
      const passwordField = document.querySelector(`#${id}`);
      passwordField.getAttribute("type") === "password"
        ? passwordField.setAttribute("type", "text")
        : passwordField.setAttribute("type", "password");
      this.passwordType = document.querySelector(`#${id}`).getAttribute("type");
    },
    async handleSubmit(el) {
      let data = {
        email: el.target.elements.email.value,
        password: el.target.elements.password.value,
      };
      await this.loginUser(data);
      if (this.isAuthenticated == true) {
        this.$router.push({ name: "AdminDashboard" });
      } else {
        this.message = "Invalid OTP";
      }
      setTimeout(() => {
        this.ld_btn = false;
        this.message = "";
      }, 3000);
    },
  },
};
</script>

<style></style>
